import React, { Suspense, useEffect, useState } from "react";
import "./CommingSoon.scss";

// assets
import Bg from "../../assets/coming-soon-bg.png";
import Bg2 from "../../assets/coming-soon-bg2.jpg";

// services
import { getTimeRemaining } from "../../services/util";
import { Canvas, useLoader } from "@react-three/fiber";
import { Loader, Sparkles, useProgress } from "@react-three/drei";
import Navbar from "../../components/Navbar/Navbar";
import Rockandcrystal from "../../Models/Rockandcrystal";
import Comet from "../../Models/Comet";

const targetedTime = new Date("2024-10-28T23:59:59");

const CommingSoon = () => {
  // states
  const [remainingTime, setRemainingTime] = useState(
    getTimeRemaining(targetedTime)
  );
  const { progress } = useProgress();

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime(getTimeRemaining(targetedTime));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="comming-soon">
      <Loader
        // containerStyles={...container} // Flex layout styles
        // innerStyles={...inner} // Inner container styles
        // barStyles={...bar} // Loading-bar styles
        // dataStyles={...data} // Text styles
        dataInterpolation={(p) => `Loading ${progress.toFixed(0)}%`} // Text
        // initialState={(active) => active} // Initial black out state
      ></Loader>
      <Suspense fallback={null}>
        <img src={Bg2} alt="" className="cs-bg" />
        <Canvas>
          {/* <Comet /> */}
          <ambientLight intensity={1} />
          <Sparkles
            size={3}
            //   color={["#C758FF", "#2142F8"]}
            scale={[10, 10, 10]}
            count={110}
            speed={0.5}
          ></Sparkles>

          <directionalLight
            color={"#D57DB6"}
            position={[-5, -5, 0]}
            intensity={5}
          />
          <directionalLight
            color={"#74CCD8"}
            position={[5, 5, -2]}
            intensity={7}
          />
          <Rockandcrystal />
        </Canvas>
        <div className="cs-body">
          <Navbar />
          <div className="csb-box">
            <div className="csbb-title">Next Smart Move</div>
            <div className="csbb-desc">Ahead Of The Curve</div>
            <div className="csb-time">
              <TimeSection label="Days">{remainingTime?.days}</TimeSection>
              <TimeSection label="Hours">{remainingTime?.hours}</TimeSection>
              <TimeSection label="Minutes">
                {remainingTime?.minutes}
              </TimeSection>
              <TimeSection label="Second">{remainingTime?.seconds}</TimeSection>
            </div>
          </div>
        </div>
      </Suspense>
    </div>
  );
};

const TimeSection = ({ label = "", children = "" }) => {
  return (
    <div className="csbt-sec">
      <div className="csbts-label">{label}</div>
      <div className="csbts-value">{children}</div>
    </div>
  );
};

export default CommingSoon;
